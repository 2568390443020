import React from 'react'
import Layout from '../components/layout/layout'
import {
  TermsBodyContainer,
  TermsHeaderSection,
  TermsSection,
} from '../components/page/terms/termsElements'
import {
  ChangesToTheAgreement,
  General,
  Indemnity,
  LimitationOfLiability,
  LinksToOtherWebsites,
  MedicalInformationDisclaimer,
  MinimumAgeRequirement,
  Payment,
  PrivacyPolicy,
  ProgramRequirements,
  ProhibitingWebsiteAccessComplaintsAndInvestigation,
  RefundCancellationPolicyTransferPolicy,
  RequirementsForOnlineCourses,
  Termination,
  TheWebsite,
  VoluntaryParticipation,
  WebsiteUsers,
} from './terms-conditions'
import { Text } from '@chakra-ui/react'

export const TopSection = () => (
  <Text component={'div'} mb={'2em'}>
    <p>
      This Terms of Use Agreement (the “Agreement”) is a legal agreement between
      you (“You” or “Your”) and The Isha Foundation Australia Inc (hereinafter
      referred to as “Isha Foundation”), pertaining to Your use of the Website.
      This Agreement sets forth the terms and conditions for You to use the
      Website and any related or successor site(s) thereto operated directly or
      indirectly by Isha Foundation.
    </p>

    <p>
      This Agreement is an ongoing contract between You and Isha Foundation and
      affects Your rights. You should read it carefully.
    </p>
  </Text>
)

export const GoverningLawJurisdiction = () => (
  <TermsSection heading='Governing Law Jurisdiction'>
    <p>
      Each party irrevocably and unconditionally submits to the non-exclusive
      jurisdiction of the State of Victoria.
    </p>
  </TermsSection>
)

const TermsBodySection = ({ showRequirements = true, variant }) => {
  return (
    <TermsBodyContainer>
      <TopSection />
      <ChangesToTheAgreement />
      <MinimumAgeRequirement />
      <TheWebsite />
      <WebsiteUsers />
      {showRequirements && <ProgramRequirements />}
      <VoluntaryParticipation />
      <MedicalInformationDisclaimer variant={variant} />
      <RequirementsForOnlineCourses variant={variant} />
      <Payment />
      <RefundCancellationPolicyTransferPolicy />
      <ProhibitingWebsiteAccessComplaintsAndInvestigation />
      <LinksToOtherWebsites />
      <PrivacyPolicy />
      <LimitationOfLiability />
      <Indemnity />
      <GoverningLawJurisdiction />
      <General />
      <Termination />
    </TermsBodyContainer>
  )
}

export default function AuTermsConditionsPage({ variant = 'goy' }) {
  return (
    <Layout>
      <TermsHeaderSection
        subtext={
          'This notice is issued by the Isha Foundation Australia Inc (“Service Provider”)'
        }
      />
      <TermsBodySection
        showRequirements={variant !== 'goy'}
        variant={variant}
      />
    </Layout>
  )
}
